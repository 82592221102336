import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    user-select: none; // disable text selection highlighting in Google Chrome
  }

  h2 {
    font-family: 'Quicksand', 'sans-serif';
  }

  p {
    font-family: 'DM Sans', 'sans-serif';
  }
  
  button {
    cursor: pointer;
  }
`;