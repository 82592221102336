import { useState, useCallback, useEffect, useMemo } from "react";
import axios from 'axios'
// import { loadStripe } from '@stripe/stripe-js'
import { useDropzone } from 'react-dropzone';
import { FaUserCircle } from "react-icons/fa";
import { IoMdCloudUpload, IoIosCheckmarkCircleOutline } from "react-icons/io";

import { useAuth } from "../../hooks/auth";
import { useFlashcard } from "../../hooks/flashcard";

import {
  AccessContainer,
  AccountDropdown,
  Box,
  BoxContainer,
  Button,
  Card,
  Container, 
  Feature, 
  FeatureList, 
  FirstContainer, 
  FirstContainerSecondSubtitle, 
  FirstContainerSubtitle, 
  FirstContainerTitle, 
  Footer, 
  FooterContainer, 
  Header,
  LoadingOverlay,
  NumericInput,
  Option,
  OptionsContainer,
  OptionsToggle,
  Price,
  PricingSection,
  SecondContainer,
  Spinner,
  SubscribeButton,
  TextArea,
  TextBoxContainer,
  ThirdContainer,
  ThirdContainerSubtitle,
  Title,
  UploadContainer,
  UploadMessage,
  User
} from "./styles"

// const stripeKey = 'pk_live_51P46MbGkEJj689TaqNCVzfyWKclClZacCGgBFtQ8aC3Qe9XTkksXUolWfxISkDkIApUi1xt906DorCQ4w5vwB7xf00XWCI0dEL'

// const priceIniciante = 'price_1P46X6GkEJj689TatmNK3WJL'
// const priceConcurseiro = 'price_1P46XzGkEJj689TaFbTfE8SD'

// let stripePromise;

// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(stripeKey);
//   }

//   return stripePromise;
// };

const roadmap = [
  {
    title: 'Passo 1: Tipo',
    description: `Escolha se você quer enviar um texto ou uma imagem para produzir os flashcards. É grátis e não é necessário ter uma conta.`,
    img: 'med1.png'
  },
  {
    title: 'Passo 2: Upload',
    description: `Faça o upload de uma imagem, tire uma foto ou forneça um texto, e deixe nossa IA criar flashcards para você. Pode ser em diversos idiomas.`,
    img: 'med2.jpg'
  },
  // {
  //   title: 'Passo 2: Quantidade',
  //   description: `Escolha a quantidade de flashcards a serem criados. Disponibilizamos a criação de até 6.000 flashcards por mês.`
  // },
  {
    title: 'Passo 3: Prática',
    description: `Baixe e importe o arquivo contendo os flashcards na plataforma da sua escolha. O arquivo exportado é compatível com a plataforma Anki e muitas outras.`,
    img: 'anki.png'
  },
]

const plans = [
  {
    title: 'Básico',
    price: '0',
    features: [
      '3 gerações',
      '10 flashcards por geração',
      '100 caracteres por upload de texto',
      'Recursos novos limitados'
    ],
    buttonColor: '#9552f8'
  },
  {
    title: 'Iniciante',
    price: '18,99',
    features: [
      '50 Gerações',
      '15 flashcards por geração',
      '1.000 caracteres por upload de texto',
      'Novos recursos em breve'
    ],
    buttonColor: '#df50b9'
  },
  {
    title: 'Concurseiro',
    price: '42,99',
    features: [
      'Gerações Ilimitadas',
      '25 flashcards por geração',
      '5.000 caracteres por upload de texto',
      'Novos recursos em breve'
    ],
    buttonColor: '#e8814a'
  }
];

const hotmartLinks = {
  'Iniciante': 'https://pay.hotmart.com/E92781791T?off=lt3e18k2&checkoutMode=10', // Link do seu produto Iniciante
  'Concurseiro': 'https://pay.hotmart.com/E92781791T?off=a57n2gzp&checkoutMode=10', // Link do seu produto Concurseiro  
};

const Home = () => {
  const { signIn, signUp, signOut, user } = useAuth()
  const { flashcard, handleGenerationDecrement, getFlashcard, resetFlashcards } = useFlashcard()

  const [optionSelected, setOptionSelected] = useState(0)
  const [text, setText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isAccountClicked, setIsAccountClicked] = useState(false)
  // const [stripeError, setStripeError] = useState(null)

  const handleSignIn = async() => {
    const result = await signIn()

    if (result) await getFlashcard()
  }

  const handleSignUp = async() => {
    const result = await signUp()
    if (result) await getFlashcard()
  }

  const handleSignOut = async() => {
    await signOut()
    resetFlashcards()
  }

  const maximumCharacters = useMemo(() => {
    const subscription = flashcard?.subscription

    return subscription === 0 ? 100 : subscription === 1 ? 1000 : 5000
  }, [flashcard])

  const handleText = useCallback((value) => {
    if (value.length <= maximumCharacters) {
      setText(value)
    }
  }, [maximumCharacters])

  const handleDownloadCSV = (csvData) => {
    // Supondo que csvData seja uma string em formato CSV
    // Se csvData não for uma string, você precisará convertê-la para CSV primeiro
  
    // Cria um Blob com os dados em formato CSV
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Verifica o tamanho do Blob em bytes
    if (blob.size < 10) { // 10 bytes
      alert('Ocorreu um erro! Tente novamente.')
      return; // Interrompe a execução da função aqui
    }
  
    // Cria uma URL para o Blob
    const url = URL.createObjectURL(blob);
  
    // Cria um elemento de link temporário
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Flashcards.csv'); // Define o nome do arquivo para download
  
    // Anexa o link ao corpo do documento
    document.body.appendChild(link);
  
    // Dispara o clique no link para iniciar o download
    link.click();
  
    // Limpa removendo o link do documento e revoga a URL do Blob
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleTextGeneration = useCallback(async () => {
    setIsLoading(true); // Ativa o loading
  
    try {
      if (flashcard?.generations === 0) {
        alert('Não há mais gerações restantes. Deseja mudar de plano?')
        return
      }

      const result = await axios.post('https://flashcard-generator-backend.onrender.com/text', {
        quantity: flashcard.subscription === 0 ? 10 : flashcard.subscription === 1 ? 15 : 25,
        text: text,
      });
      
      handleDownloadCSV(result.data)

      await handleGenerationDecrement(user?.uid)
    } catch (error) {
      console.error(error);
      alert('Ocorreu um erro! Tente novamente.')
    } finally {
      setIsLoading(false); // Desativa o loading
    }
  }, [text, handleGenerationDecrement, flashcard, user])

  const onDrop = useCallback(async (acceptedFiles) => {
    if (flashcard?.generations === 0) {
      alert('Não há mais gerações restantes. Deseja mudar de plano?')
      return
    }

    const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'))
  
    if (imageFiles.length === 0) {
      alert('Mande no formato de imagem.')
      return 
    }

    const formData = new FormData();
    formData.append('image', imageFiles[0]);
    formData.append('quantity', flashcard.subscription === 0 ? 10 : flashcard.subscription === 1 ? 15 : 25,);

    setIsLoading(true);

    try {
      const result = await axios.post('https://flashcard-generator-backend.onrender.com/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      
      handleDownloadCSV(result.data)

      await handleGenerationDecrement(user?.uid)
    } catch (error) {
      console.error(error);
      alert('Ocorreu um erro! Tente novamente.')
    } finally {
      setIsLoading(false); // Desativa o loading
    }
  }, [user, handleGenerationDecrement, flashcard])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    }, 
    multiple: false
  });

  // const checkoutOptions = (item) => ({
  //   lineItems: [{
  //     price: item,
  //     quantity: 1
  //   }],
  //   mode: "subscription",
  //   successUrl: `${window.location.origin}/`,
  //   cancelUrl: `${window.location.origin}/`
  // })

  // const redirectToCheckout = async (item) => {
  //   if (!user) {
  //     alert('Acesse sua conta para poder fazer a assinatura.')
  //     return
  //   }

  //   console.log("redirectToCheckout");

  //   const stripe = await getStripe();
  //   const { error } = await stripe.redirectToCheckout(checkoutOptions(item));
  //   console.log("Stripe checkout error", error);

  //   if (error) setStripeError(error.message);
  // };

  // if (stripeError) alert(stripeError);
  
  const redirectToCheckout = (plan) => {
    if (!user) {
      alert('Acesse sua conta para poder fazer a assinatura.');
      return;
    }

    window.location.href = hotmartLinks[plan];
  };

  return (
    <Container>
      {isLoading &&  
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      }

      <Header>
        <h2
          style={{
            fontSize: 32,
            color: '#0f5bff'
          }}
        >
          ResiFlash
        </h2>

        {user
          ? <User onClick={() => setIsAccountClicked(!isAccountClicked)} >
              <FaUserCircle size={24} />

              <p
                style={{
                  fontSize: 20,
                  marginLeft: 8
                }}
              >
                {user.displayName.split(' ')[0]}
              </p>

              {isAccountClicked &&
                <AccountDropdown>
                  {/* <div>
                    <h2
                      style={{
                        color: '#1d2b60',
                        fontWeight: 600,
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                      }}
                    >
                      Minha conta
                    </h2>
                  </div> */}
                  
                  <div onClick={handleSignOut} >
                    <h2
                      style={{
                        color: '#1d2b60',
                        fontWeight: 600,
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                      }}
                    >
                      Sair
                    </h2>
                  </div>
                </AccountDropdown>
              }
            </User>
          : <AccessContainer>
              <h2 onClick={handleSignIn} >Login</h2>

              <h2 onClick={handleSignUp} >Cadastrar</h2>
            </AccessContainer>
        }
      </Header>

      <FirstContainer>
        <FirstContainerTitle>
          Gerador de Flashcards com IA
        </FirstContainerTitle>

        <FirstContainerSubtitle>
          Preparando-se para uma prova ou exame? O criador de flashcards da ResiFlash transforma automaticamente seus textos e fotos de anotações, páginas de livros e qualquer outra imagem que contenha assuntos relacionados à Medicina em flashcards usando o poder da IA. Basta fazer o upload dos seus materiais e nossa IA criará seus flashcards em segundos.
        </FirstContainerSubtitle>

        <BoxContainer>
          {roadmap.map((value, index) => (
            <>
              <Box key={index} >
                <img 
                  src={require(`../../assets/${value.img}`)} alt='Imagem'
                  style={{
                    height: 120,
                    borderRadius: index === 1 ? 16 : 0,
                    marginBottom: 12
                  }}
                />

                <h2
                  style={{
                    color: '#1d2b60',
                    fontWeight: 800,
                    marginBottom: '12px',
                    fontSize: '23px',
                    letterSpacing: '0.5px',
                  }}
                >
                  {value.title}
                </h2>

                <p
                  style={{
                    fontWeight: 400,
                    lineHeight: '23px',
                    fontSize: '14.5px',
                    letterSpacing: '0.5px',
                    color: '#30417d'
                  }}
                >
                  {value.description}
                </p>
              </Box>
              
              {index !== 2 &&
                <img 
                  src={require('../../assets/arrow.png')} alt='Imagem'
                  style={{
                    height: 48,
                    margin: '0 24px'
                  }}
                />
              }
            </>
          ))}
        </BoxContainer>

        <FirstContainerSecondSubtitle>
          Criar flashcards para seus estudos nunca foi tão fácil. Escolha o modo de impressão em um lado para uma abordagem rápida de cortar e colar, ou opte pelo modo de impressão em dois lados, que vem automaticamente alinhado para sua conveniência.
        </FirstContainerSecondSubtitle>

        <h2
          style={{
            color: '#1d2b60',
            fontWeight: 600,
            margin: '0 0 12px',
            fontSize: 24,
            letterSpacing: '0.5px',
          }}
        >
          Funciona em mais de 50 idiomas, incluindo inglês 🇬🇧
        </h2>
      </FirstContainer>

      <SecondContainer>
        <p
          style={{
            fontWeight: 400,
            lineHeight: '23px',
            fontSize: '16px',
            margin: '0 0 28px',
            color: '#30417d',
            letterSpacing: '0.5px'
          }}
        >
          Experimente fazendo o upload de uma foto do seu livro ou anotações de um caderno. Funciona até mesmo com texto.
        </p>

        <OptionsContainer>
          <OptionsToggle>
            <Option
              onClick={() => setOptionSelected(0)}
              isSelected={optionSelected === 0}
            >
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '15px',
                  color: '#30417d',
                  letterSpacing: '0.5px'
                }}
              >Texto</p>
            </Option>

            <Option
              onClick={() => setOptionSelected(1)}
              isSelected={optionSelected === 1}
            >
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '15px',
                  color: '#30417d',
                  letterSpacing: '0.5px'
                }}
              >Imagem</p>
            </Option>
          </OptionsToggle>

          <p
            style={{
              fontWeight: 400,
              fontSize: '15px',
              color: '#30417d',
              letterSpacing: '0.5px'
            }}
          >Gerações restantes: {flashcard?.generations}</p>
        </OptionsContainer>

        {
          optionSelected === 0
            ? <>
                <TextBoxContainer>
                  <TextArea
                    value={text}
                    onChange={e => handleText(e.target.value)}
                    placeholder="Digite o texto..."
                  />
                </TextBoxContainer>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                  >
                  <Button
                    onClick={handleTextGeneration}
                  >
                    <h2
                      style={{
                        fontWeight: 600,
                        fontSize: '15px',
                        color: '#0f5bff',
                        letterSpacing: '0.5px'
                      }}
                    >
                      Gerar
                    </h2>
                  </Button>
                    
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: '16px',
                      color: '#30417d',
                      letterSpacing: '0.5px'
                    }}
                  >
                    {text.length}/{maximumCharacters} caracteres
                  </p>
                </div>
              </>
            : <UploadContainer {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  isDragActive
                    ? <UploadMessage>Solte a imagem aqui ...</UploadMessage>
                    : <UploadMessage>Arraste e solte uma imagem aqui ou clique para selecionar uma imagem</UploadMessage>
                }
                <IoMdCloudUpload
                  size={100}
                  color='#667197'
                  style={{
                    marginTop: 20,
                    marginBottom: -60
                  }}
                />
              </UploadContainer>
        }
      </SecondContainer>

      <ThirdContainer>
        <h2
          style={{
            color: '#1d2b60',
            fontWeight: 600,
            margin: '0 0 12px 0',
            fontSize: '30px',
            letterSpacing: '0.5px',
          }}
        >
          Assine para gerar Flashcards ilimitados
        </h2>

        <ThirdContainerSubtitle>
          Desbloqueie o potencial completo dos seus materiais de estudo com nossos planos de assinatura. Sejam suas anotações ou páginas de um livro, nossa IA cria flashcards que tornam o estudo mais impactante e menos demorado.
        </ThirdContainerSubtitle>

        <PricingSection>
          {plans.map(({ title, price, buttonColor, features }, index) => (
            <Card key={index} >
              <Title buttonColor={buttonColor} >{title}</Title>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'self-end',
                }}
              >
                <Price>R$ {price}</Price>
                <Price>/ mês</Price>
              </div>
              
              <FeatureList>
                {features.map((feature, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: -8
                    }}
                  >
                    <IoIosCheckmarkCircleOutline size={28} color={buttonColor} />

                    <Feature>
                      {feature}
                    </Feature>
                  </div>
                ))}
              </FeatureList>

              {
                title !== 'Básico' && 
                flashcard?.subscription !== index && 
                <SubscribeButton 
                  isLast={title === 'Concurseiro'} 
                  buttonColor={buttonColor}
                  // onClick={() => redirectToCheckout(title === 'Concurseiro' ? priceConcurseiro : priceIniciante)}
                  onClick={() => redirectToCheckout(title)}
                >
                  Assinar
                </SubscribeButton>
              }
            </Card>
          ))}
        </PricingSection>
      </ThirdContainer>

      <FooterContainer>
        <Footer>
          <h2>© 2024 ResiFlash</h2>

          <h2>Todos os direitos reservados.</h2>
        </Footer>
      </FooterContainer>
    </Container>
  )
}

export default Home