import { createContext, useContext, useState } from "react";
import { collection, getDocs, setDoc, doc } from 'firebase/firestore'

import { db } from '../services/firebase'

const flashcardsCollection = collection(db, 'flashcards')

const FlashcardContext = createContext({})

const plans = {
  0: {
    generations: 3, // gerações que a pessoa tem restantes 
    subscription: 0 // [0, 1, 2] / na ordem como ta na home
  },
  1: {
    generations: 50,
    subscription: 1
  },
  2: {
    generations: 9999,
    subscription: 2
  }
}

export const FlashcardProvider = ({ children }) => {
  // const [data, setData] = useState(plans[0])
  const [data, setData] = useState(() => {
    const plan = localStorage.getItem('@Flashcard:plan')

    if (plan) {
      return JSON.parse(plan)
    }

    return plans[0]
  });

  const getFlashcard = async (userId) => {
    const data = await getDocs(flashcardsCollection)

    const dataFormmatted = {}

    for (let x = 0 ; x < data.docs.length ; x++) {
      const newObj = {
        [data.docs[x].id]: {
          ...data.docs[x].data()
        }
      }

      Object.assign(
        dataFormmatted,
        newObj
      )
    }

    if (dataFormmatted[userId]) {
      setData(dataFormmatted[userId])
    }

    return dataFormmatted[userId]
  }

  const updateSubscription = async (userId, subscription = 0) => {
    const updateData = plans[subscription]

    await setDoc(doc(flashcardsCollection, userId), updateData)

    setData(updateData)

    localStorage.setItem('@Flashcard:plan', JSON.stringify(updateData));
  }

  const handleGenerationDecrement = async (userId) => {
    const updateData = {
      ...data,
      generations: data.generations - 1
    }

    if (userId) {
      await setDoc(doc(flashcardsCollection, userId), updateData)
    }

    setData(updateData)

    localStorage.setItem('@Flashcard:plan', JSON.stringify(updateData))
  }

  const resetFlashcards = () => {
    const updateData = {
      subscription: 0,
      generations: 0
    }

    setData(updateData)

    localStorage.setItem('@Flashcard:plan', JSON.stringify(updateData))
  }

  return (
    <FlashcardContext.Provider
      value={{
        flashcard: data,
        getFlashcard,
        updateSubscription,
        handleGenerationDecrement,
        resetFlashcards
      }}
    >
      {children}
    </FlashcardContext.Provider>
  )
}

export const useFlashcard = () => {
  const context = useContext(FlashcardContext);

  if (!context) {
    // se o FlashcardContext ñ foi criado, ou seja, caso não haja .Provider ao redor
    throw new Error('The context must be used within an .Provider');
  }

  return context;
}