import styled, { keyframes, css } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
`

export const Header = styled.div`
  height: 64px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
  padding: 20px 120px;
  background: hsla(0,0%,100%,.6);

  @media (max-width: 1200px) {
    padding: 20px 20px;
  }
`

export const User = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover {
    transition-duration: 0.3s;

    p {
      color: #1362f0;
    }
  }
`

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f5fc;
  padding: 20px;
  text-align: center;
  width: 100%;
`

export const FirstContainerTitle = styled.h2`
  color: #1d2b60;
  font-weight: 600;
  margin: 0 0 12px 0;
  font-size: 35px;
  letter-spacing: 0.5px;

  @media (max-width: 1230px) {
    font-size: 30px;
  }
`

export const FirstContainerSubtitle = styled.p`
  font-weight: 400;
  line-height: 23px;
  font-size: 16px;
  margin-bottom: 25px;
  width: 68%;
  letter-spacing: 0.5px;
  color: #30417d;

  @media (max-width: 1230px) {
    font-size: 15px;
    width: 88%;
  }
`

export const FirstContainerSecondSubtitle = styled.p`
  font-weight: 400;
  line-height: 23px;
  font-size: 16px;
  margin-bottom: 25px;
  width: 72%;
  color: #30417d;
  letter-spacing: 0.5px;

  @media (max-width: 1230px) {
    width: 88%;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 1230px) {
    flex-direction: column;
    
    img {
      visibility: hidden;
    }
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 16px; 
  box-shadow: 0 0 28px rgba(12, 8, 51, 0.09);
  padding: 16px;
  width: 340px;
  height: 300px;

  img {
    visibility: visible;
  }
`

export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  text-align: center;
`

export const OptionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: -12px;
  }
`

export const OptionsToggle = styled.div`
  display: flex;
  padding: 4px;
  background: #eff2fe;
  border-radius: 99px;

  @media (max-width: 560px) {
    margin-bottom: 16px;
  }
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border-radius: 99px;
  background: ${props => props.isSelected ? '#fff' : '#eff2fe'};
  cursor: pointer;
`

export const TextBoxContainer = styled.div`
  background-color: #eff2fe;
  padding: 16px;
  width: 1200px;
  height: 400px;
  margin: 20px auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media (max-width: 1230px) {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  resize: none;

  color: #1d2b60;
  font-size: 15px;
  font-family: 'DM Sans', 'sans-serif';
  
  &:focus {
    outline: none;
  }
`;

export const Button = styled.div`
  border-radius: 15px;
  background-color: transparent;
  border: 2px solid #0f5bff;
  padding: 15px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transition-duration: 0.3s;
    background: rgba(15, 91, 255, 0.1);
  }

  @media (max-width: 1230px) {
    padding: 15px 54px;
  }
`

export const UploadContainer = styled.div`
  width: 1200px;
  height: 400px;
  margin: 20px auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: #eff2fe;
  border-radius: 12px;
  border: 4px dashed #ccd4f3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: border .24s ease-in-out;
  cursor: pointer;

  @media (max-width: 1230px) {
    width: 100%;
  }
`;

export const UploadMessage = styled.h2`
  font-weight: 600;
  font-size: 23px;
  color: #667197;
  letter-spacing: '0.5px';
`;

export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3); /* Cor mais clara para o círculo de "loading" */
  border-top: 4px solid #fff; /* Cor mais escura para a parte superior do círculo */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${rotate} 1.2s linear infinite;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
  font-size: 2rem;

  /* Adicionando o Spinner ao centro do Overlay */
  & > ${Spinner} {
    /* Ajustes adicionais ao Spinner, se necessário */
  }
`;

export const ThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f5fc;
  padding: 24px;
  text-align: center;
  width: 100%;
`

export const ThirdContainerSubtitle = styled.p`
  font-weight: 400;
  line-height: 23px;
  font-size: 16px;
  margin-bottom: 25px;
  width: 68%;
  letter-spacing: 0.5px;
  color: #30417d;

  @media (max-width: 1230px) {
    width: 100%;
  }
`

export const PricingSection = styled.section`
  display: flex;
  justify-content: center;
  padding: 20px;

  @media (max-width: 1230px) {
    flex-direction: column;
    padding: 0px;
  }
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 28px rgba(12,8,51,.09);
  margin: 15px;
  padding: 20px;
  width: 376px; // or whatever width you'd like
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 550px) {
    width: 100%;
    margin: 15px 0;
  }
`;

export const Title = styled.h2`
  color: ${props => props.buttonColor};
  font-size: 31px;
  font-weight: 800;
  margin-bottom: 12px;
  letter-spacing: .5px;
`;

export const Price = styled.p`
  color: #213066;
  font-weight: 600;
  font-size: 42px;
  letter-spacing: .5px;
  margin-right: 8px;

  & + p {
    font-size: 25px;
    margin-bottom: 6px;
    margin-right: 0;
  }
`;

export const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
`;

export const Feature = styled.p`
  font-weight: 400;
  line-height: 52.5px;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #30417d;
  margin-left: 8px;

  @media (max-width: 450px) {
    font-size: 12px;
    margin-left: 10px;
  }
`

export const SubscribeButton = styled.button`
  background-color: ${props => props.buttonColor};
  border: none;
  width: 100%;
  border-radius: 15px;
  color: #fff;
  padding: 16px;
  margin-top: 20px;
  opacity: 0.92;
  font-weight: 700;
  font-size: 16px;
  font-family: Quicksand, sans-serif;
  cursor: pointer;
  letter-spacing: 0.5px;
  transition-duration: 0.3s;

  &:hover {
    opacity: 1;
  }

  ${props =>
    props.isLast &&
    css`
      background-color: transparent;
      border: 2.5px solid #e8814a;
      color: #e8814a;

      &:hover {
        background-color: rgba(232, 129, 74, 0.12);
      }
    `
  }
`;

export const AccessContainer = styled.div`
  display: flex;
  align-items: center;

  h2 {
    color: #1d2b60;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: .5px;
    cursor: pointer;

    & + h2 {
      margin-left: 24px;
    }

    &:hover {
      color: #0f5bff;
    }
  }
`

export const NumericInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  
  color: #1d2b60;
  font-size: 15px;
  font-family: 'DM Sans', 'sans-serif';
  
  &:focus {
    outline: none;
  }
  
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  -moz-appearance: textfield; /* Firefox */
`;

export const AccountDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 36px;
  left: 0;
  box-shadow: 0 3px 15px rgba(12, 8, 51, 0.12);
  background: hsla(0, 0%, 100%, .7);
  transition: background-color .2s ease-in-out;
  backdrop-filter: saturate(100%) blur(13px);
  border-radius: 11px;
  min-width: 120px;
  padding: 6px;

  div {
    padding: 9px;
    border-radius: 8px;

    &:hover {
      background: #efefef;
    }
  }
`

export const FooterContainer = styled.div`
  background: linear-gradient(0deg, #252525, #373737);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  
  padding: 36px 12px;

  h2 {
    font-size: 16px;
    color: #787c8b;
    letter-spacing: .5px;
  }

  @media (max-width: 540px) {
    padding: 24px 8px;

    h2 {
      font-size: 14px;
    }
  }
`