import React from 'react';

import { FlashcardProvider } from './flashcard';
import { AuthProvider } from './auth';

const AppProvider = ({ children }) => (
  <FlashcardProvider>
    <AuthProvider>
      {children}
    </AuthProvider>
  </FlashcardProvider>
);

export default AppProvider;